import React, { useState, useCallback } from "react"
import { useForm } from "react-hook-form"
import { useDropzone } from "react-dropzone"
import queryString from "query-string"
import Spinner from "./components/spinner"
import { FormType } from "../../types"
import { postTestimonial } from "../../api/testimonial-video"
import { width } from "styled-system"

type FormProps = {
  form: FormType
  [key: string]: any
}

type TestimonialFormData = {
  name: string
  email: string
  facebook: string
  instagram: string
  video: File
}

const TestimonialVideoForm = ({ form, ...props }: FormProps) => {
  const search = props?.location?.search
  const parsedSearch = queryString.parse(search)
  const [stateData, setData] = useState({
    isSubmitting: false,
    progress: 0,
  })
  const [email, setEmail] = useState(parsedSearch.email || ``)
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()
  const { getRootProps, getInputProps, acceptedFiles, fileRejections, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: "video/*,.zip,.rar,.7zip",
    maxFiles: 1,
    maxSize: 250000000, // 250mb
    multiple: false,
  })

  let dropZoneLabel = undefined

  if (acceptedFiles.length > 0) {
    let path = acceptedFiles[0].path

    setValue(`video`, path)
    dropZoneLabel = `${path} - ${(acceptedFiles[0].size / 1000000).toFixed()} mb`
  } else if (fileRejections.length > 0) {
    setValue(`video`, undefined)
    dropZoneLabel = `The selected file is not valid`
  }

  const nameClasses = `mb-6 p-3 block w-full bg-inputBackground ${errors.name ? `border-red-500 border-2` : ``}`
  const facebookClasses = `mb-6 p-3 block w-full bg-inputBackground`
  const instagramClasses = `mb-6 p-3 block w-full bg-inputBackground`
  const emailClasses = `mb-6 p-3 block w-full bg-inputBackground ${errors.email ? `border-red-500 border-2` : ``}`
  const fileClasses = `mb-6 ${errors.video || isDragReject ? `border-red-500 border-2` : ``}`
  const buttonClasses = `p-3 w-full button ${stateData.isSubmitting ? `disabled` : `primary`}`

  React.useEffect(() => {
    register(`video`, { required: true })
  }, [register])

  const updateLoadingProgress = (progress: number) => {
    setData({ isSubmitting: true, progress: Math.round(progress * 100) })
  }

  const onSubmit = async (data: TestimonialFormData) => {
    setData({ isSubmitting: true, progress: 0 })

    if (typeof marketingDataLayer !== `undefined`) {
      console.log(`Push to GTM data layer`)
      marketingDataLayer.push({
        event: `gaForm`,
        eventCategory: `Form`,
        eventAction: `Story Form`,
        eventLabel: location.pathname,
      })
    } else {
      console.log(`GTM data layer is not loaded`)
    }

    try {
      const responseCode =
        (await postTestimonial({
          file: acceptedFiles[0],
          name: data.name,
          email: data.email,
          facebook: data.facebook,
          instagram: data.instagram,
          progressCallback: updateLoadingProgress,
        })) || 0

      setData({ ...stateData, isSubmitting: false })

      if (responseCode >= 200 && responseCode < 300) {
        location.assign(`${form.destination}`)
      } else {
        alert(`Your request was not sent successfully.`)
      }
    } catch (err) {
      setData({ ...stateData, isSubmitting: false })
      alert(`Your request was not sent successfully.  ${err.message}`)
    }
  }

  return (
    <form id="testimonial-video-v2" name="testimonial-video-v2" method="POST" onSubmit={handleSubmit(onSubmit)}>
      <div className="max-w-lg mx-auto">
        <div {...getRootProps({ className: `dropzone mb-1 flex flex-col gap-4 items-center p-6 tracking-wide cursor-pointer bg-inputBackground ${fileClasses}` })}>
          <input {...getInputProps({ name: "video", id: "video" })} />

          <svg className="flex-none w-36 h-36" fill="#C5C5C5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
          </svg>

          <div className="hidden md:inline-block flex-grow text-center">
            {dropZoneLabel ? (
              dropZoneLabel
            ) : (
              <>
                <h3 className="">Drag &amp; Drop</h3>
                <p>
                  or <span className="text-blue-300">click</span> to choose
                </p>
                <div className="text-sm">max size is 250mb!</div>
              </>
            )}
          </div>

          <div className="inline-block md:hidden flex-grow text-center">
            {dropZoneLabel ? (
              dropZoneLabel
            ) : (
              <>
                <h3 className="">Record</h3>
                <p>
                  or <span className="text-blue-300">click</span> to choose
                </p>
                <div className="text-sm">max size is 250mb!</div>
              </>
            )}
          </div>
        </div>

        <input {...register("name", { required: true })} type="text" className={nameClasses} placeholder="Name" autoComplete="name" />
        <input {...register("facebook", { required: false })} type="text" className={facebookClasses} placeholder="Facebook@" />
        <input {...register("instagram", { required: false })} type="text" className={instagramClasses} placeholder="Instagram@" />
        <input
          {...register("email", { required: true })}
          type="email"
          className={emailClasses}
          placeholder="Email"
          autoComplete="email"
          value={email}
          onChange={(e: { target: { value: any } }) => {
            setEmail(e.target.value)
          }}
        />
      </div>

      <div className="max-w-lg mx-auto text-center mt-5">
        {Object.keys(errors).length > 0 && (
          <div className="bg-red-400 p-3 mb-2 text-white rounded">{(errors.consent && Object.keys(errors).length === 1 && `Please accept the terms.`) || `Please fix errors in your form.`}</div>
        )}

        <button type="submit" className={buttonClasses} disabled={stateData.isSubmitting}>
          {stateData.isSubmitting && <Spinner className="float-left h-7 w-5 text-white" size={5} color="white" />}
          {form.submitText || `Submit`}
        </button>
      </div>

      <div className={`max-w-lg mx-auto pt-1 ${stateData.isSubmitting ? `relative` : `hidden`}`}>
        <div className="flex mb-2 items-center justify-between">
          <div>
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase">{stateData.progress > 0 ? `Upload in progress` : `Preparing data`}</span>
          </div>
          <div className="text-right">
            <span className="text-xs font-semibold inline-block">{stateData.progress}%</span>
          </div>
        </div>
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
          <div style={{ width: `${stateData.progress}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-highlightBackground"></div>
        </div>
      </div>
    </form>
  )
}

export default TestimonialVideoForm
