type Props = {
  file: File
  name: string
  email: string
  facebook: string
  instagram: string
  progressCallback: any
}

export const postTestimonial = async function (props: Props): Promise<number | undefined> {
  return 200
}
